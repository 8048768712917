<template>
  <el-page-header>
    <template #content>
      <span class="text-large font-600 mr-3"> Contar </span>
    </template>
    <div class="mt-4 text-sm font-bold">
      Si algo no responde presione las teclas CTRL + SHIFT + R
    </div>
  </el-page-header>
</template>
<script>
export default {
  name: 'SidebarMenu'
}
</script>
<style scoped>
  .el-menu-demo {
    font-size: 18px;
    background-color: #adf3ff;
  }

  .el-menu-demo .el-menu-item {
    color: #333;
  }

  .el-menu-demo .el-submenu__title {
    color: #333;
  }

  .el-menu-demo .el-submenu__title:hover {
    background-color: #f5f5f5;
  }

  .el-menu-demo .el-menu-item.is-active {
    background-color: #fff;
  }

  .flex-grow {
    flex-grow: 1;
  }
</style>
