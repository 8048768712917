<template>
  <title>New Title</title>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
