<template>
  <div class="main">
    <div class="signup">
        <div class="title">
          <label for="chk">Login</label>
        </div>
        <el-form :model="loginForm" label-position="top" label-width="80px">
        <el-form-item label="Username" required>
          <el-input v-model="loginForm.username" placeholder="Ingrese su usuario"></el-input>
        </el-form-item>
        <el-form-item label="Password" required>
          <el-input v-model="loginForm.password" type="password" placeholder="Ingrese su password" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login" class="submit-btn">Login</el-button>
        </el-form-item>
      </el-form>
      <el-alert v-if="errorMsg" title="Error" type="error">{{errorMsg}}</el-alert>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginPage',
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      errorMsg: ''
    }
  },
  methods: {
    async login () {
      if (!this.loginForm.username || !this.loginForm.password) {
        this.errorMsg = 'Por favor, ingrese un usuario y password'
      } else {
        try {
          const response = await axios.post('https://toolkit.easy-docking.com.ar/api/login', {
            username: this.loginForm.username,
            password: this.loginForm.password
          })
          const data = response.data
          // console.log(data)
          if (!data.failed && data.code === 200) {
            localStorage.setItem('ed_facturar_token', data.token) // Store the token in local storage
            this.$router.push('/recordstable') // Redirect the user to the recordstable page
          } else {
            // Display error message if login is unsuccessful
            this.errorMsg = data.succes
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Jost', sans-serif;
  background: #f6f6f6;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.signup {
  position: relative;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.signup .title {
  color: #000000;
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.signup input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.signup button {
  width: 100%;
  height: 40px;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s ease-in-out;
}

.signup button:hover {
  background-color: #5b53f1;
}

.signup label {
  color: #000000;
  font-size: 1em;
  margin-bottom: 10px;
  display: block;
}

.signup .message {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  opacity: 0;
  transition: .5s ease-in-out;
}

.signup:hover .message {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .signup {
    max-width: 300px;
  }

  .signup .title {
    font-size: 1.5em;
  }

  .signup input {
    font-size: .9em;
  }

  .signup button {
    font-size: 1em;
  }

  .signup label {
    font-size: .9em;
  }
}

@media screen and (max-width: 480px) {
  .signup {
    max-width: 250px;
  }

  .signup .title {
    font-size: 1.3em;
  }

  .signup input {
    font-size: .8em;
    padding: 8px;
  }

  .signup button {
    font-size: .9em;
  }

  .signup label {
    font-size: .8em;
  }

  .signup .message {
    height: 50px;
    font-size: 1em;
  }

  .signup .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
