import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

import LoginPage from './components/LoginPage.vue'
import RecordsTable from './components/RecordsTable.vue'

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: LoginPage },
  { path: '/recordstable', component: RecordsTable }
]

// Create a router instance
const router = createRouter({
  history: createWebHistory(),
  routes
})

const app = createApp(App)

app.use(ElementPlus)
app.use(router)

app.mount('#app')
